<template>
    <div class="hotel-setup__table-content" v-if="areas.length">
        <table class="table">
            <thead class="table__table-head">
                <th>Floor</th>
                <th>Area</th>
                <th>Options</th>
            </thead>
            <tbody>
                <tr v-for="area in areas" :key="area.id">
                    <td>{{ area.floorName }} {{ area.floorPrefix }}</td>
                    <td>{{ area.areaName }}</td>
                    <td>
                        <div class="table__options">
                            <AreasQrMaker :area-id="area.id" />
                            <router-link title="Edit Area" :to="`/hotel_setup/areas/edit/${area.id}`" >
                                <img src="@/assets/icons/vuesax/linear/edit.svg" width="15" height="15" />
                            </router-link>
                            <a href="#" title="Delete Area" @click.prevent="showModal(area.id)">
                                <img src="@/assets/icons/vuesax/linear/trash.svg" width="15" height="15" />
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Table paginator component -->
    <TablePaginator ref="paginator" :api-url="areasListApi" :filter="filterWords" v-model="areas" />

    <!-- Empty table records component -->
    <TableEmptyData title="Your Areas Is Empty"
        message="We detect that you have not yet registered any area, register them before using the application..."
        v-if="!areas.length" />

    <!-- Modal delete component -->
    <ModalDelete ref="modalDelete" @on-delete="refreshList" />
</template>

<script setup>
import ModalDelete from '@/common/ModalDelete.vue'
import TablePaginator from '@/common/TablePaginator.vue'
import TableEmptyData from '@/common/TableEmptyData.vue'
import AreasQrMaker from './AreasQrMaker.vue'
import { ref } from 'vue';

defineProps(['filterWords'])

const areasListApi = `/web/areas`;
const modalDelete = ref(null)
const paginator = ref(null)
let areas = ref([])

function showModal(areaId) {
    const deleteAreaApi = `/web/areas/delete/${areaId}`
    modalDelete.value.showModal(deleteAreaApi)
}

function refreshList() {
    paginator.value.refreshPage()
}
</script>