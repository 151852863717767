<template>
    <a href="#" @click.prevent="makeQrCode">
        <img src="@/assets/icons/bx_qr_blue.svg" width="15" height="15" />
    </a>
</template>

<script setup>
import { areasServices } from '../Services/AreasServices'

const props = defineProps(['areaId'])

const { qrMakerService } = areasServices()

async function makeQrCode() {
    const response = await qrMakerService(props.areaId)
    const qr = response.data

    let a = document.createElement("a"); //Create <a>
    a.href = "data:image/png;base64," + qr.qr; //Image Base64 Goes here
    a.download = `${qr.areaName}`; //File name Here
    a.click(); //Downloaded file
}
</script>