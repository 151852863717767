import { httpRequests } from "../../../helpers/HttpRequests"

export function areasServices() {
    const { getRequest, postRequest, putRequest, deleteRequest, httpErrors } = httpRequests()
    const errors = httpErrors

    function getFloorsWithoutPagination() {
        return getRequest('/web/floors/without_pagination')
    }

    function addAreaService(area) {
        return postRequest('/web/areas/add', area)
    }

    function editAreaService(areaId) {
        return getRequest(`/web/areas/edit/${areaId}`)
    }

    function updateAreaService(areaId, area) {
        return putRequest(`/web/areas/update/${areaId}`, area)
    }

    function deleteAreaService(areaId) {
        return deleteRequest(`/web/areas/delete/${areaId}`)
    }

    function qrMakerService(areaId) {
        return getRequest(`/web/areas/qr_maker/${areaId}`)
    }

    function qrCodesSheet() {
        return getRequest(`/web/areas/qr_sheet`, { 'responseType': 'blob' })
    }

    return {
        getFloorsWithoutPagination,
        addAreaService,
        editAreaService,
        updateAreaService,
        deleteAreaService,
        qrMakerService,
        qrCodesSheet,
        errors
    }
}