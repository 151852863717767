<template>
    <div>
        <a href="#" @click.prevent="downloadQrRoomsSheet">
            QR Codes Sheet
        </a>
    </div>
</template>

<script setup>
import { areasServices } from '../Services/AreasServices'

const { qrCodesSheet } = areasServices()

async function downloadQrRoomsSheet() {
    const response = await qrCodesSheet()

    if (response.hasErrors) return console.error(`Error making qr areas sheet: ${response.message}`)

    const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
    const fileLink = document.createElement('a')

    fileLink.href = fileURL;
    fileLink.setAttribute('target', '_blank')
    document.body.appendChild(fileLink)
    fileLink.click()
}
</script>